import { createSlice } from '@reduxjs/toolkit';
import { editPatient, getOnePatient, getPatients } from './patientThunk';
const initialState = {
    data: [],
    onData: undefined,
    patientToUpdate: null,
    loading: false,
    getOnePatientLoading: false,
    editPatientStatus: 'idle',
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        clearOnePatient: (state) => {
            state.onData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatients.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getPatients.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getPatients.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getOnePatient.pending, (state, actions) => {
            state.getOnePatientLoading = true;
        })
            .addCase(getOnePatient.fulfilled, (state, { payload }) => {
            state.getOnePatientLoading = false;
            state.onData = payload.data;
        })
            .addCase(getOnePatient.rejected, (state, { payload }) => {
            state.error = true;
            state.getOnePatientLoading = false;
        })
            .addCase(editPatient.pending, (state, actions) => {
            state.editPatientStatus = 'loading';
        })
            .addCase(editPatient.fulfilled, (state, { payload }) => {
            state.editPatientStatus = 'success';
        })
            .addCase(editPatient.rejected, (state, { payload }) => {
            state.editPatientStatus = 'failed';
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { clearOnePatient } = slice.actions;
